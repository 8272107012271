/* GRAPH STUFF */
.avl-graph {
  /* background-color: var(--graph-bg-color); */
}
.avl-graph rect.avl-stack,
.avl-graph rect.avl-grid,
.avl-graph path.avl-slice {
  transition: fill-opacity 0.15s ease-out;
  fill-opacity: 0.75;
}
.avl-graph rect.avl-stack:hover,
.avl-graph path.avl-slice:hover {
  fill-opacity: 1;
}
.avl-graph g.avl-grid-horizontal:hover rect.avl-grid {
  fill-opacity: 1;
}
.avl-graph .graph-line.secondary {
  stroke-dasharray: 4 4;
}
.avl-graph g.axis-group {
  pointer-events: none;
}
.avl-graph g.axis path.domain {
  stroke-width: 2px;
}
.avl-graph g.axis path.domain {
  stroke-width: 2px;
}
.avl-graph g.axis.secondary path.domain {
  stroke-dasharray: 4 4;
}
.avl-graph g.axis g.tick text {
  font-size: 0.75rem;
}

/* HOVER COMP STUFF */
.hover-comp {
  /* background-color: var(--graph-bg-color); */
}
.hover-comp table.hover-table > thead > tr > th {
  padding: 1px 5px 0px 5px;
  text-align: center;
  /* border-bottom: 2px solid #000; */
}
.hover-comp table.hover-table > tbody > tr > td {
  padding: 1px 5px;
  text-align: center;
  /* border-bottom: 1px solid #000; */
}
.hover-comp table.hover-table > tbody > tr > td:first-child {
  padding: 1px 5px 1px 0px;
  text-align: left;
}
.hover-comp table.hover-table > tbody > tr > td:last-child {
  padding: 1px 0px 1px 5px;
  text-align: right;
}
.hover-comp .color-square {
  transition: background-color 0.15s ease-out;
  /* background: currentColor; */
}
.hover-comp .secondary .color-square {
  background: repeating-linear-gradient(-45deg, currentColor 2px, currentColor 4px, transparent 4px, transparent 6px);
}
