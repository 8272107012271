@import url(styles/main.css);

button, button.btn, a.btn {
	box-shadow: none !important;
	outline: none !important;
}
button:disabled,
button.btn:disabled,
a.btn.disabled {
	cursor: not-allowed !important;
}
